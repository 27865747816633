<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
      <div class="row">
        <div class="col-md-6">
          <p class="h4 font-weight-bold">Thông tin khách hàng</p>
          <div class="form-group">
            <label class="form-label col-form-label">Họ và tên<span class="text-danger">*</span></label>
            <ValidationProvider vid="name" name="Họ và tên" rules="required" v-slot="{ errors }">
              <el-input filterable class="w-100" placeholder="Họ và tên" v-model="contract.name"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Số điện thoại<span class="text-danger">*</span></label>
            <ValidationProvider vid="phone" name="Số điện thoại" rules="required" v-slot="{ errors }">
              <el-input disabled filterable class="w-100" placeholder="Số điện thoại"
                        v-model="contract.phone"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Số điện thoại khác<span class="text-danger"></span></label>
            <ValidationProvider vid="phone_other" name="Nhập số điện thoại khác" rules=""
                                v-slot="{ errors }">
              <el-input v-model="contract.phone_other" filterable class="w-100"
                        placeholder="Nhập số điện thoại khác"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Email<span class="text-danger">*</span></label>
            <ValidationProvider vid="email" name="Nhập Email" rules="required" v-slot="{ errors }">
              <el-input v-model="contract.email" filterable class="w-100" placeholder="Nhập Email"></el-input>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Ngày sinh<span class="text-danger">*</span></label>
            <ValidationProvider vid="birthday" name="Ngày sinh" rules="required" v-slot="{ errors }">
              <el-date-picker
                  v-model="contract.birthday"
                  type="date"
                  format="dd-MM-yyyy"
                  placeholder="Nhập ngày sinh">
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <p class="h4 font-weight-bold mb-4">Tạo đơn hàng</p>
          <div class="form-group">
            <label class="form-label col-form-label">Trung tâm đăng ký học<span class="text-danger">*</span></label>
            <ValidationProvider vid="center_id" name="Trung tâm" rules="required" v-slot="{ errors }">
              <el-select filterable v-model="contract.center_id" placeholder="Chọn trung tâm" clearable
                         @input="handleCenter">
                <el-option
                    v-for="item in centers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label col-form-label">Chi nhánh học tập<span class="text-danger">*</span></label>
            <ValidationProvider vid="branch_id" name="Chi nhánh" rules="required" v-slot="{ errors }">
              <el-select filterable v-model="contract.branch_id" placeholder="Chọn chi nhánh" clearable
                         @input="handleBranch">
                <el-option
                    v-for="item in branches"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <p class="h4 font-weight-bold mb-4">Danh sách khóa học</p>
          <div v-for="(item, index) in contract.courses" :key="index" class="courses">
            <div class="form-group">
              <label class="form-label col-form-label">Khóa học {{ index + 1 }}<span
                  class="text-danger">*</span></label>
              <div class="row">
                <div class="col-md-10">
                  <ValidationProvider :vid="'courses.'+index+'.id'" name="Khóa học" rules="required"
                                      v-slot="{ errors }">
                    <el-select filterable v-model="item.id" placeholder="Chọn khóa học" clearable
                               @input="handleCourse(item)">
                      <el-option
                          v-for="item in courses"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="mt-2 col-md-2 cursor-pointer" @click="removeCourse(index)">
                  <i class="el-icon-delete  font-weight-bold text-danger fa-lg"></i>
                </div>
              </div>
            </div>
          </div>
          <el-button class="mt-4" @click="addNewCourse()" size="mini" type="primary" icon="el-icon-plus">Thêm khóa
          </el-button>
          <div>
            <label class="form-label col-form-label">Voucher & Quà tặng</label>
          </div>
          <div class="col-md-12 d-flex ">
            <el-badge :value="numberCode" class="item ml-3" type="success">
              <el-button @click="viewVoucher()" size="small"> Chọn voucher & quà tặng</el-button>
            </el-badge>
          </div>
          <div v-if="list_promotions_selected.length > 0">
            <div class="promotions row mt-4" v-for="(item, index) in list_promotions_selected" :key="index">
              <div class="col-md-5 font-weight-bold">
                <span v-if="item.type">Quy đổi quà tặng - {{ item.name }}</span>
                <span v-if="item.type == 0">{{ item.name }}</span>
                <span v-if="item.type == 0">(Voucher)</span>
              </div>
              <div class="col-md-6 font-weight-bold">
              <span v-if="item.unit === 0">
                <span class="badge badge-success"> {{ item.value }}%</span>
              </span>
                <span v-else>
                <span class="badge badge-success">{{ item.value | formatVND }}</span>
              </span>
              </div>
              <div class="col-md-1">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <p class="h4 font-weight-bold">Xác thực số điện thoại</p>
          <div class="form-group row">
            <div class="col-md-3">
              <label class="form-label col-form-label">Số điện thoại cần xác thực<span
                  class="text-danger"></span></label>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="contract.phone" name="Số điện thoại" rules="required" v-slot="{ errors }">
                <el-input disabled filterable class="w-100" placeholder="Số điện thoại"
                          v-model="contract.phone"></el-input>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-3" v-if="!isShow">
              <button class="btn btn-primary" :disabled="contract.phone && verify.loading_send" @click="sendOtp">
                <i class="el-icon-loading mr-1" v-if="verify.loading_send"></i>
                Gửi mã
              </button>
            </div>
          </div>
          <div class="form-group row" v-if="!contract.is_verify">
            <div class="col-md-3">
              <label class="form-label col-form-label">Mã OTP<span
                  class="text-danger"></span></label>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="verify.otp" name="OTP" rules="" v-slot="{ errors }">
                <el-input type="number" filterable class="w-100" placeholder="Mã xác thực"
                          v-model="verify.otp"></el-input>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-3">
              <button
                  @click="verifyOtp"
                  :disabled="verify.otp && verify.loading_send" class="btn btn-primary">
                <i v-if="verify.loading_send" class="el-icon-loading mr-1"></i>
                Xác thực
              </button>
            </div>
          </div>
          <div v-else class="d-flex justify-content-center">
            <button class="btn btn-success"><i class="el-icon-check">Đã xác thực</i></button>
          </div>
          <p class="h4 font-weight-bold mt-2">Thông tin đơn hàng</p>
          <!-- Khóa học-->
          <p class="h5 font-weight-bold">Danh sách khóa học</p>
          <div v-if="contract.courses.length">
            <hr>
            <div class="d-flex justify-content-between mb-2"
                 v-for="(item, index) in contract.student_register_courses"
                 :key="index">
                <span>
                  <i class="el-icon-menu mr-4"></i>
                  <span class="font-weight-bold">{{ item.course.name }}</span>
                </span>
              <span class="font-weight-bold">{{ item.fee | formatVND }}</span>
            </div>
            <!--End-->
          </div>

          <hr>
          <div class="d-flex justify-content-between">
            <p class="h5">Tổng giá gốc</p>
            <p class="h5">{{ contract.total | formatVND }}</p>
          </div>
          <div class="d-flex justify-content-between ">
            <p class="h5">Tổng khuyến mại</p>
            <p class="text-success h5">{{ contract.total_promotions | formatVND }}</p>
          </div>
          <div class="d-flex justify-content-between ">
            <p class="font-weight-bold h4">Tổng thanh toán</p>
            <p class="text-danger h4">{{ contract.total_need | formatVND }}</p>
          </div>
          <hr>
          <div class="gift" v-if="gift_select?.description">
            <p class="font-weight-bold h4">Quà tặng</p>
            <el-input
                :value="gift_select.description"
                :rows="5"
                type="textarea"
                placeholder="Quà tặng"
                disabled = "true"
            />
          </div>
        </div>
      </div>
    </form>
    <el-dialog
        id="gift"
        title="Chọn quà tặng"
        :visible.sync="giftDialogVisible"
        center
        style="right: 12px !important;"
        append-to-body
    >
      <div class="list-code-container">
        <div v-if="listGift.length > 0">
          <div
              class="border border-info"
              v-for="item in listGift"
              :key="item.id"
              :v-if="item.id"
          >
            <div class="border border-info mb-3" style="background-image: linear-gradient(to bottom, #DFEAFF,#FFFFFF)">
              <GiftDialog
                  :item="item"
                  :listPromotions="list_promotions_selected"
                  :giftSelect="gift_select"
                  @pickPrice="pickPromotions(item)"
                  @pickGift="pickGift(item)"
                  @showDetailPromotion="showDetailPromotion(item)"
                  @cancelGift="cancelGift(item)"
              ></GiftDialog>
            </div>
          </div>
        </div>
        <div v-else class="no-promotion">
          <p>Không có mã khuyến mại</p>
        </div>
      </div>
    </el-dialog>
    <!-- Modal show combo   -->
    <el-dialog
        :title="titleDetail"
        :visible.sync="detailPromotionDialogVisible"
        width="40%"
        center
        style="right: 12px !important"
        append-to-body
    >
      <div class="row">
        <div class="col-xl-6 col-md-12">
          <h1 style="font-size: 14px">Tên:</h1>
          <el-input
              placeholder="Please input"
              :value="promotionDetail.nameCode"
          >
          </el-input>
          <h1 style="font-size: 14px" class="mt-4">Ngày bắt đầu:</h1>
          <el-input
              placeholder="Please input"
              :value="promotionDetail.startDate"
          >
          </el-input>
        </div>
        <div class="col-xl-6 col-md-12">
          <h1 style="font-size: 14px">Giá trị:</h1>
          <el-input
              placeholder="Please input"
              :value="`${promotionDetail.amount}${promotionDetail.unit == MONEY?'VND':'%'}`"
          >
          </el-input>
          <h1 style="font-size: 14px" class="mt-4">Ngày kết thúc:</h1>
          <el-input
              placeholder="Please input"
              :value="promotionDetail.endDate"
              :disabled="true">
          </el-input>
        </div>
        <div class="col-12">
          <h1 style="font-size: 14px" class="mt-4">Mô tả voucher:</h1>
          <el-input
              type="textarea"
              placeholder="Please input"
              :value="promotionDetail.description"
              :disabled="true">
          </el-input>
        </div>
      </div>
    </el-dialog>

    <!-- End   -->
    <!-- Modal show voucher   -->
    <el-dialog
        id="voucher"
        title="Danh sách voucher"
        :visible.sync="voucherDialogVisible"
        center
        style="right: 12px !important;"
        append-to-body
    >
      <hr style="margin-top: -26px;">
      <div class="list-code-container">
        <div class="row search-voucher" style="margin-bottom: 30px">
          <div class="col-md-12">
            <el-input v-model="searchVoucher" @input="searchVoucherByName()"
                      placeholder="Nhập tên voucher"></el-input>
          </div>
        </div>
        <div v-if="loadingVoucherGift">
          <i class="el-icon-loading"></i>
          loading...
        </div>
        <div v-else >
          <div v-if="is_edutalk">
            <div>
              <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình Flash sale</h1>
              <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[FLASH_SALE]:allCode[FLASH_SALE]" @openDetail="showDetailPromotion" :colorCode="'#FF8C22'" :allCode='allCode' :type="FLASH_SALE" @pickPromotions="morePickPromotions(FLASH_SALE)"></VoucherDialog>
            </div>
            <div class="mt-4">
              <h1 style="font-size: 14px;font-weight: bold;margin-bottom: 0px;text-align: left">Chương trình định kỳ</h1>
              <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[PERIODIC_PROMOTIONS]:allCode[PERIODIC_PROMOTIONS]" @openDetail="showDetailPromotion" :colorCode="'#1BC5BD'" :allCode='allCode' :type="PERIODIC_PROMOTIONS" @pickPromotions="morePickPromotions(PERIODIC_PROMOTIONS)"></VoucherDialog>
            </div>
            <div class="mt-4">
              <h1 style="font-size: 14px;font-weight: bold;margin-bottom: 0px;text-align: left">Chương trình liên kết trường</h1>
              <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[SCHOOL_LINK]:allCode[SCHOOL_LINK]" @openDetail="showDetailPromotion" :colorCode="'#3343D0  '" :allCode='allCode' :type="SCHOOL_LINK" @pickPromotions="morePickPromotions(SCHOOL_LINK)"></VoucherDialog>
            </div>
          </div>
          
          <div class="mt-4">
            <h1 style="font-size: 14px;font-weight: bold;margin-bottom: 0px;text-align: left">Chương trình khác</h1>
            <VoucherDialog :arrayVoucher="arrayVoucher" :listCode="searchVoucher?listCodeSearch[OTHER_SCHOOL]:allCode[OTHER_SCHOOL]" @openDetail="showDetailPromotion" :colorCode="'#D40000'" :allCode='allCode' :type="OTHER_SCHOOL" @pickPromotions="morePickPromotions(OTHER_SCHOOL)"></VoucherDialog>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="Check CIC"
        :visible.sync="checkCicDialogVisible"
        center
        style="right: 12px !important;"
        append-to-body
    >
      <div class="row">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="/media/video/cic.mp4"
                  allowfullscreen></iframe>
        </div>
      </div>
    </el-dialog>
    <!-- End   -->
  </ValidationObserver>

</template>

<script>
import {GET_CENTERS} from "@/core/services/store/center/center.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {GET_COURSES} from "@/core/services/store/course/course.module";
import {FIND_PROMOTION, LIST_COMBO, LIST_VOUCHER} from "@/core/services/store/contract/contract.module";
import {CUSTOMER_FIND} from "@/core/services/store/customer/customer.module";
import {
  CONTRACT_ONLINE_GET_TUITION,
  CONTRACT_ONLINE_SEND_OTP_BY_PHONE,
  CONTRACT_ONLINE_VERIFY_PHONE_PHONE
} from "@/core/services/store/contract/contractOnline.module";
import CheckCicVideo from "@/views/pages/customer/components/DialogCheckCicVideo";
import {CENTER_DOI_TAC} from "@/core/config/center/centerOption";
import {DAI_HOC, EDUCATIONS, THPT} from "../../../../core/config/contract/contractOnlineOption";
import {GET_SCHOOL_ALL} from "../../../../core/services/store/contract/school.module";
import {GET_PROVINCES} from "../../../../core/services/store/public/provinces.module";
import {TYPE_GIFT, TYPE_VOUCHER} from "../../../../core/config/promotionOption";
import {LIST_GIFT} from "../../../../core/services/store/contract/contract.module";
import GiftDialog from "./GiftDialog";
import {Code_Model} from "@/views/pages/customer/models/Code_Model";
import {mapGetters} from "vuex";
import {
  FLASH_SALE,
  MONEY,
  OTHER_SCHOOL,
  PERCENT,
  PERIODIC_PROMOTIONS,
  SCHOOL_LINK
} from "@/core/config/voucher_code/voucher_code";
import VoucherDialog from "@/views/pages/customer/components/VoucherDialog.vue";

export default {
  name: "RegisterStudyFormPartner",
  components: {VoucherDialog, GiftDialog, CheckCicVideo},
  props: {
    errorsForm: {
      type: Object,
      default: () => {
        return null;
      }
    },
    contract_prop: {
      type: Object,
      default: () => {
        return null;
      }
    },
    verify_prop: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isShow: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      combo_image: `${process.env.VUE_APP_BASE_URL}media/svg/combo.svg`,
      voucher_image: `${process.env.VUE_APP_BASE_URL}media/svg/voucher.svg`,
      numberCombo: 0,
      total: 0,
      total_promotions: 0,
      total_need: 0,
      promotionDetail: [],
      listPromotion: [],
      listGift: [],
      gift_select: null,
      listVoucher: [],
      list_promotions_selected: [],
      giftDialogVisible: false,
      comboDialogVisible: false,
      voucherDialogVisible: false,
      checkCicDialogVisible: false,
      detailPromotionDialogVisible: false,
      searchVoucher: '',
      customer: null,
      verify: {
        otp: '',
        loading_send: false,
        loading_verify: false
      },
      contract: {
        is_verify: false,
        tuition_payment_id: 1,
        is_older: true,
        name: '',
        is_confirm: 1,
        status: 1,
        phone: '',
        phone_other: '',
        email: '',
        birthday: '',
        center_id: '',
        branch_id: '',
        customer_id: this.$route.query.customer_id,
        total: '',
        total_need: 0,
        total_promotions: 0,
        education_id: '',
        school_id: '',
        province_id: '',
        is_edutalk: '',
        courses: [
          {
            id: "",
            name: "",
            fee: 0
          }
        ],
        student_register_courses : [
          {
            course: {},
            fee: '',
          }
        ],
        list_promotions: [],
        gift_id: [],
        parent: {
          type: 'bo',
          other_value: '',
          name: '',
          phone: '',
          phone_other: '',
        }
      },
      courses: [],
      centers: [],
      branches: [],
      tuitions: [],
      type: [],
      schools: [],
      provinces: [],
      checkDialogSDTPhuHuynh: false,
      educations: EDUCATIONS,
      loadingVoucherGift: false,
      PERCENT:PERCENT,
      MONEY: MONEY,
      SCHOOL_LINK: SCHOOL_LINK,
      FLASH_SALE: FLASH_SALE,
      PERIODIC_PROMOTIONS: PERIODIC_PROMOTIONS,
      OTHER_SCHOOL: OTHER_SCHOOL,
      allCode: [],
      numberCode:0,
      listCodeSearch:{},
      arrayVoucher: []
    }
  },
  watch: {
    verify_prop: {
      handler(newVal, oldVal) {
        if (this.contract_prop.id) {
          this.verify = this.verify_prop;
        }
      },
    },
    contract: {
      handler(newVal, oldVal) {
        this.contract.list_promotions = this.list_promotions_selected;
        this.$emit('dataContract', this.contract);
      },
      deep: true,
      immediate: true
    },
    contract_prop: {
      handler(newVal, oldVal) {
        this.contract = this.contract_prop;
        this.calcPrice();
        this.getListCombo();
        this.getCenter();
        this.getBranch();
        this.getCourse();
        this.getTuition();
        this.list_promotions_selected = this.contract && this.contract.list_promotions ? this.contract.list_promotions : [];
        if (this.contract.education_id && this.contract.province_id) {
          this.getSchool();
        }
      },
      deep: true,
      immediate: true,
    },
    errorsForm: {
      handler() {
        if (_.size(this.errorsForm)) {
          this.$refs.form.setErrors(
              this.errorsForm
          );
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.getProvince();
    this.getCenter();
    this.getBranch();
    this.getCourse();
    this.getCustomer();
    this.getTuition();
    this.openListVoucher();
    this.is_edutalk = this.currentUser.is_edutalk;
  },
  computed: {
    disableSelectSchool() {
      if (this.contract.education_id && this.contract.province_id) {
        return true;
      }
      return false;
    },
    isShowProvince() {
      if ([THPT, DAI_HOC].includes(this.contract.education_id)) {
        return true;
      }
      return false;
    },
		numberGift() {
			return this.listGift.length;
		},
		numberVoucher() {
			return this.listVoucher.length;
		},
		titleDetail() {
			if (this.promotionDetail.type === TYPE_VOUCHER) {
				return "Chi tiết voucher";
			} else {
				return "Chi tiết quà tặng";
			}
		},
		titlePriceDetail() {
			if (this.promotionDetail == this.TYPE_VOUCHER) {
				return 'Giảm ngay:';
			} else {
				return "Giá trị quy đổi tiền mặt:";
			}
		},
		titleDesc() {
			if (this.promotionDetail == this.TYPE_VOUCHER) {
				return 'Chi tiết quà tặng :';
			} else {
				return "Mô tả voucher";
			}
		},
    ...mapGetters(["currentUser","getCountWaitUser"]),
  },
  methods: {
    getProvince() {
      this.$store.dispatch(GET_PROVINCES).then((data) => {
        this.provinces = data.data;
      })
    },
    handleEducation() {
      this.contract.school_id = '';
      this.getSchool();
    },
    async getSchool() {
      if (!this.contract.education_id || !this.contract.province_id) {
        return false;
      }
      await this.$store.dispatch(GET_SCHOOL_ALL, {
        education_id: this.contract.education_id,
        province_id: this.contract.province_id,
      }).then((data) => {
        this.schools = data.data;
      })
    },
    getQuantityCourse() {
      return this.total_course = this.contract.courses.filter((item) => {
        return item.id > 0;
      }).length;
    },
    resetGift() {
      this.contract.gift_id = [];
      this.gift_select = null;
    },
    cancelGift(item) {
      this.resetGift();
      this.unPickPromotions(item);
    },
    pickGift(event) {
      this.contract.gift_id.push(event.id);
      this.gift_select = event;
    },
    setGiftSelect(listGift) {
      if (this.contract.gift_id.length < 0) {
        return false;
      }
      let gift_id = this.contract.gift_id[0];
      for (let i = 0; i < listGift.length; i++) {
        if (listGift[i].id === gift_id) {
          this.gift_select = listGift[i];
        }
      }
    },
    getListGift() {
      this.listGift = [];
      if (this.contract.branch_id) {
        this.$store.dispatch(LIST_GIFT, {
          'branch_id': this.contract.branch_id,
          'quantity_course': this.getQuantityCourse()
        }).then((response) => {
          this.listGift = response.data;
          let giftModel = this.listGift.map((voucher)=>{
            return new Code_Model(voucher?.program_id,voucher?.branch_id,voucher?.course,voucher?.course_ids,voucher?.gift,voucher?.id,voucher?.is_center,voucher?.type,voucher?.value,voucher?.name,0,voucher?.start_date,voucher?.end_date,voucher?.description, voucher.unit,voucher.value,true,true)
          })
          let voucherModel = this.listVoucher?.map((voucher)=>{
            return new Code_Model(voucher.program_id,voucher?.branch_id,voucher?.course,voucher?.course_ids,voucher?.gift,voucher?.id,voucher?.is_center,voucher?.type,voucher?.value,voucher?.name,+voucher?.is_flash_sale,voucher?.start_date,voucher?.end_date,voucher?.description, voucher.unit,voucher.amount)
          })
          let code = (voucherModel.concat(giftModel)).map((voucher)=> this.list_promotions_selected.find((e)=>e.id ==voucher.id ) || this.contract.gift_id.find((b)=> b == voucher.id ) ?{...voucher,selected:true}:voucher)
          this.numberCode = code.length;
          this.allCode = _.groupBy(code,({program_id})=>program_id)
          this.listGiftSearch = [...this.listGift];
          this.setGiftSelect(this.listGift);
        }).finally(()=>{
          this.loadingVoucherGift = false
        });
      }
    },
    openListGift() {
      this.giftDialogVisible = true;
    },
    getTuition() {
      this.$store.dispatch(CONTRACT_ONLINE_GET_TUITION).then((data) => {
        this.tuitions = data.data;
      });
    },
    getCenter() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        is_edutalk: CENTER_DOI_TAC,
        status: 1
      }).then((data) => {
        this.centers = data.data.data;
      });
    },
    getBranch() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_id: this.contract.center_id,
        noibo: true
      }).then((data) => {
        this.branches = data.data;
      });
    },
    getCourse() {
      this.$store.dispatch(GET_COURSES, {
        per_page: 10000,
        center_id: this.contract.center_id,
        branch_id: this.contract.branch_id,
        status: 1
      }).then((data) => {
        this.courses = data.data.data;
      });
    },
    getCustomer() {
      let id = this.$route.query.customer_id ? parseInt(this.$route.query.customer_id) : '';
      if (id) {
        this.$store.dispatch(CUSTOMER_FIND, id).then((data) => {
          this.customer = data.data;
          this.contract.name = this.customer.name;
          this.contract.phone = this.customer.phone;
          this.contract.email = this.customer.email;
          this.contract.birthday = (this.customer.ngay_sinh == '0000-00-00' || !this.customer.ngay_sinh) ? '' : this.customer.ngay_sinh
        });
      }
    },
    summit() {
    },
    addNewCourse: function () {
      this.contract.courses.push({
        id: '',
        name: '',
        fee: '',
      });
      this.contract.student_register_courses.push({
        course: {},
        fee: '',
      });
    },
    removeCourse(index) {
		  this.resetGift();
      this.list_promotions_selected = [];
      this.checkVoucherSelected();
      this.contract.courses.splice(index, 1);
      this.contract.student_register_courses.splice(index, 1);
      this.calcPrice();
    },
		resetVoucher() {
			this.list_promotions_selected = [];
			this.contract.list_promotions = [];
		},
		handleCourse(event) {
			this.resetVoucher();
			this.openListVoucher();
      this.checkVoucherSelected();
      this.addValue(event);
      this.calcPrice();
      this.getBranch();
      this.getListCombo();
      this.$forceUpdate();
    },
    setCourseDefault() {
    	return [
    		{
    			id: '',
    			name: '',
    			fee: '',
    		}
    	]
    },
    setRegisterCourseDefault() {
    	return [
    		{
    			course: {},
    			fee: '',
    		}
    	]
    },
    handleCenter() {
      this.type = [2, 3];
      this.branches = [];
      this.contract.branch_id = '';
      this.getCourse();
      this.contract.courses = this.setCourseDefault();
      this.contract.student_register_courses = this.setRegisterCourseDefault();
      this.getListCombo();
      this.openListVoucher();
      this.getBranch();
    },
    handleBranch() {
      this.getListCombo();
      this.contract.courses = this.setCourseDefault();
      this.contract.student_register_courses = this.setRegisterCourseDefault();
      this.openListVoucher();
      this.getCourse();
      this.getListGift();
    },
    searchVoucherByName() {
      if (this.searchVoucher.length == 0) {
        this.openListVoucher();
      } else {
        [FLASH_SALE,PERIODIC_PROMOTIONS,SCHOOL_LINK].forEach((value)=>{
          this.listCodeSearch[value] = this.allCode[value].filter((code) =>
              code.name.toLowerCase().includes(this.searchVoucher.toLowerCase())
          );
        })
      }
    },
    calcPrice() {
      this.total = 0;
      let total_normal = 0;
      let courses = this.contract.courses;
      let register_courses = this.contract.student_register_courses;
      let courses_flash_sale = [];
      // tìm trong promotion course flash sale
      for (let i = 0; i < this.list_promotions_selected.length; i++) {
        if (this.list_promotions_selected[i].is_flash_sale == 1 && this.list_promotions_selected[i].course.id) {
          courses_flash_sale.push(this.list_promotions_selected[i].course.id)
        }
      }
      for (let i = 0; i < register_courses.length; i++) {
        this.total = this.total + register_courses[i].fee;
        if (!courses_flash_sale.includes(register_courses[i].course.id)) {
          total_normal = total_normal + register_courses[i].fee;
        }
      }
      // Cacl promotion
      let value_promotions_total = 0;
      this.list_promotions_selected.map((promo) => {
        if (promo.unit == 1) {
          value_promotions_total += promo.value;
          promo.value_money = promo.value;
        } else {
          if (promo.is_flash_sale == 1) {
            // flash sale
            value_promotions_total += promo.course.fee * promo.value / 100;
            promo.value_money = promo.course.fee * promo.value / 100;
          } else {
            value_promotions_total += total_normal * promo.value / 100;
            promo.value_money = total_normal * promo.value / 100;
          }
        }

      });
      this.total_promotions = value_promotions_total;
      this.contract.total_promotions = value_promotions_total;
      this.total_need = this.total - value_promotions_total;
      this.contract.total_need = this.total - value_promotions_total;
      this.contract.total = this.total;
    },
    addValue(event) {
      this.courses.map((course) => {
        this.contract.courses.map((item, index) => {
          if (course.id == item.id) {
            this.contract.courses[index].name = course.name;
            this.contract.courses[index].fee = course.fee;
            this.contract.courses[index].promotion_flash_sale = course.promotion_flash_sale;
          }
        })
      })
      let found = this.contract.courses.findIndex((element) => element.id == event.id);
      this.contract.student_register_courses[found].fee = event.fee;
      this.contract.student_register_courses[found].course = event;
      this.checkUniqueCourse(event);
    },
    checkUniqueCourse(event) {
      let courses = this.contract.courses;
      let count_unique = 0;
      let index = 0;
      for (let i = 0; i <= courses.length; i++) {
        let item = courses[i];
        if (item && item.id) {
          if (item.id === event.id) {
            index = i;
            count_unique++;
          }
        }
      }
      if (count_unique > 1) {
        this.contract.courses.splice(index, 1)
        this.contract.student_register_courses.splice(index, 1)
        this.$message.error('Khóa học đã được chọn');
        return false;
      }
    },
    openListCombo() {
      this.comboDialogVisible = true;
      this.getListCombo();
    },
    viewVoucher() {
      this.voucherDialogVisible = true;
      this.openListVoucher();
    },
    async openListVoucher() {
      this.searchVoucher = '';
      let courseSend = [];
      _.each(this.contract.courses, function (course) {
        courseSend.push(course.id);
      });
      await this.$store.dispatch(LIST_VOUCHER, {
        'name': this.searchVoucher,
        'branch_id': this.contract.branch_id,
        'center_id': this.contract.center_id,
        'course_ids': courseSend,
				'tuition_payment_id': this.contract.tuition_payment_id,
				'quantity_course': this.getQuantityCourse(),
      }).then((response) => {
        this.listVoucher = response.data;
        this.getListGift()
        this.checkVoucherSelected();
      });
      await this.checkVoucherSelected();
    },
    async getListCombo() {
      let courseSend = [];
      _.each(this.contract.courses, function (course) {
        courseSend.push(course.id);
      });
      await this.$store.dispatch(LIST_COMBO, {
        'courses': courseSend,
        'center_id': this.contract.center_id,
        'branch_id': this.contract.branch_id
      }).then((response) => {
        this.listPromotion = response.data;
        this.numberCombo = this.listPromotion.length;
      });
    },
    showDetailPromotion(item) {
      this.promotionDetail = item;
      this.detailPromotionDialogVisible = true;
    },
    pickPromotions(event) {
      if (!event.amount && event.amount != null) {
        this.$message.error('Voucher bạn chọn đã hết lượt sử dụng');
        return;
      }
      this.comboDialogVisible = false;
      this.voucherDialogVisible = false;
      this.value_promotion = event.value;
      this.promotion_id = event.id;
      this.promotion_name = event.name;
      this.promotion_unit = event.unit;
      let temp = this.list_promotions_selected;
      _.each(temp, function (value, key) {
        if (event.type === 1 || event.type === 2) {
          if (value.type === 1 || value.type === 2) {
            temp.splice(key, 1);
          }
        }
      });
      temp.push(event);
      this.list_promotions_selected = temp;
      this.calcPrice();
      this.check_promotion = true;
      this.checkVoucherSelected();
      this.arrayVoucher.push(event.id)
    },
    morePickPromotions(key){
      console.log('keykeykey', key);
      this.list_promotions_selected = []
      this.contract.gift_id = [];
      this.gift_select = null;
      this.allCode[key].filter((code)=>code.selected === true).forEach((item)=>{
        item.isUseGift ? this.pickGift(item) : this.pickPromotions(item)
      })
    },
    unPickPromotions(data) {
      this.list_promotions_selected = this.list_promotions_selected.filter(item => item.id !== data.id);
      this.checkVoucherSelected();
      this.calcPrice();
      // If delete gift then reset select gift
      if (data && data.type === TYPE_GIFT) {
        this.resetGift();
      }
    },
    checkVoucherSelected() {
      let temp = this.list_promotions_selected;
      let arr_FS = [];
      temp.map(function (item) {
        if (item.is_flash_sale == 1 && item.course) {
          arr_FS.push(item.course.id)
        }
      });
      this.listVoucher.map(function (item) {
        if (item.is_flash_sale == 1 && arr_FS.length > 0) {
          item.is_selected_FS = 1;
        } else {
          item.is_selected_FS = 0;
        }
        let index_selected = temp.findIndex(e => e.id === item.id);
        if (index_selected >= 0) {
          item.is_selected = 1;
        } else {
          item.is_selected = 0;
        }
      });
    },
    sendOtp() {
      // this.contract.is_verify = false;
      this.verify.loading_send = true;
      this.$store.dispatch(CONTRACT_ONLINE_SEND_OTP_BY_PHONE, {
        phone: this.contract.phone
      }).then((data) => {
        this.$message.success(data.message);
      }).catch((error) => {
        this.$message.error(error.data.message);
      }).finally(() => {
        this.verify.loading_send = false;
      });
    },
    verifyOtp() {
      this.verify.loading_send = true;
      this.$store.dispatch(CONTRACT_ONLINE_VERIFY_PHONE_PHONE, {
        phone: this.contract.phone,
        otp: this.verify.otp
      }).then((data) => {
        this.contract.is_verify = true;
        this.$message.success(data.message);
      }).catch((error) => {
        this.$message.error(error.data.message);
      }).finally(() => {
        this.verify.loading_send = false;
      });
    }
  }
}
</script>

<style scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

.el-select {
  width: 100%;
}
</style>

<style>
#voucher .el-dialog--center {
  width: 75%;
}

#voucher .el-dialog__body {
  text-align: center;
}

#promotion .el-dialog--center {
  width: 75%;
}

#promotion .el-dialog__body {
  text-align: center;
}

@media (min-width: 1024.5px) {
  #voucher .el-dialog--center {
    width: 40%;
  }


  #promotion .el-dialog--center {
    width: 40%;
  }
}

</style>
