<template>
  <el-dialog
      title="Check CIC"
      :visible.sync="checkCicDialogVisible"
      width="40%"
      center
      style="right: 12px !important;"
      append-to-body
  >
    <div class="row">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                allowfullscreen></iframe>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogCheckCicVideo",
  props: {
    checkCicDialogVisible: {
      type: Boolean,
      default: () => {
        return false;
      },
    }
  }
}
</script>

<style scoped>

</style>